import React, { useEffect } from 'react';
import AppPageContainer from '../../../components/AppPageContainer';
import ToolBar from '../../../components/ToolBar';
import { Auth } from '../../../modules/auth_module';
import { Org } from '../../../modules/organizations_module';

const OrganizationPage = () => {

    const org = Org.use()
    const auth = Auth.use();
    console.log(org)
    // useEffect(() => {
    //     fetchOrg(auth.activeOrg)
    // }, []);

    return (
        <AppPageContainer activePage="organization">
            <ToolBar pageName="Your Organization" />
            <div className="border bg-yellow-100 border-warning rounded-lg mt-4 p-2">
                <h1 className="text-xl font-bold">New Features Coming Soon</h1>
                <p className="text-gray-800">We're still finishing out some features with Kodama's dasahboard, and we plan on getting these features done soon.</p>
                <ul className="list-inside list-disc">
                    <li>Invite, add and remove users in your organization</li>
                    <li>Assigning roles and permissions</li>
                </ul>
            </div>
            <div className="mt-4 p-3 border border-gray-200 rounded-lg w-full shadow-md">
                <div className="grid gap-4 grid-cols-8 pb-2 mb-2 font-bold border-b border-gray-300">
                    <div className="col-span-1 flex items-center">Role</div>
                    <div className="col-span-1 flex items-center">First Name</div>
                    <div className="col-span-1 flex items-center" >Last Name</div>
                    <div className="col-span-4 flex items-center">Email</div>
                    <div className="col-span-1 flex items-center">
                        <div className="border rounded border-primary w-full text-center py-1 px-3 text-primary hover:bg-green-100 cursor-pointer">Add User</div>
                    </div>
                </div>
                {org.org?.Members && org.org?.Members.map(mem => (
                    <div key={mem.User.UUID} className="grid gap-4 grid-cols-8 text-gray-800">
                        <div className="col-span-1">{mem.Role}</div>
                        <div className="col-span-1">{mem.User.FirstName}</div>
                        <div className="col-span-1" >{mem.User.LastName}</div>
                        <div className="col-span-5">{mem.User.Email}</div>
                    </div>
                ))}
            </div>
        </AppPageContainer>
    )
}

export default OrganizationPage;
